import React, { useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa';

function Main() {
  const [topic, setTopic] = useState('');
  const [limerick, setLimerick] = useState('');

  const messages = [
    {
        role:'system',
        content: `You are a talented limerick writer. Who would return a 
        limerick on any given topic of no more than 5 lines. The shyming scheme
        you can follow is AABBA.`   
    },
    {
        role:'user',
        content: `${topic}`
    }
  ]

  const func = async () => {
    try {
        const url = 'https://openai-api-worker.taneja-simranjot.workers.dev/';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(messages)
        })

        //converting the response into a JSON
        const data = await response.json()
        console.log(data)
        var formattedResponse = data.content.replace(/\n/g, "<br>");
        setLimerick(formattedResponse)
    } catch(err) {
        console.log(err);
    }
}

return (
    <main className='mt-4 flex flex-col items-center gap-7'>
      <p className='text-blue-500'>Instant limericks on any topic, powered by AI!</p>
      <div className='flex gap-4'>
      <label>
        Limerick Topic: 
        {/* Read onChange={e} as onChange of event show what is changed at event.target as value */}
        <input 
          className='w-64 ml-4 p-2 border border-solid border-blue-400 rounded-md' 
          type='text' 
          placeholder='Enter the topic here' 
          value={topic} 
          onChange={e => {setTopic(e.target.value)}}
        />
      </label>
      <button 
          className='bg-blue-500 text-white rounded-md p-2' 
          onClick={func}>
          <FaPencilAlt />
      </button>
      </div>
      <div>  
        <div className=' text-center' dangerouslySetInnerHTML={{ __html: limerick }} /> 
      </div>
    </main>
  )
}

export default Main
