import React from 'react'

function Header() {
  return (
    <header className='flex justify-center align-middle h-16 items-center '>
      <h1 className='text-3xl '>AILimerick Buddy</h1>
    </header>
  )
}

export default Header
